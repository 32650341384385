import React, { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'

import Button from 'aws-northstar/components/Button'
import Stack from 'aws-northstar/layouts/Stack'
import Text from 'aws-northstar/components/Text'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'
import Modal from 'aws-northstar/components/Modal'
import Inline from 'aws-northstar/layouts/Inline'
import Link from 'aws-northstar/components/Link'
import Add from '@material-ui/icons/Add'

import ServicesList from './ServicesList'

import { CloudService } from 'models'

// :: ---

function ManageServicesPage() {
  const [services, setServices] = useState([])
  const [currentService, setCurrentService] = useState('')
  const [open, setOpen] = useState(false)

  const fetchServices = async () => {
    console.debug('Fetching AWS Services ...')
    const services = await DataStore.query(CloudService)
    console.debug('Services:', services)

    setServices(services)
  }
  const passFunction = async (industry) => {
    setOpen(true)
    setCurrentService(industry)
  }
  const handleClose = () => {
    setOpen(false)
    setCurrentService('')
  }
  const deleteCurrentService = async () => {
    const result = await DataStore.delete(currentService)
    console.log(result)
    fetchServices()
    setOpen(false)
  }

  // :: ---

  useEffect(() => {
    fetchServices()
  }, [])

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Manage AWS Services'
        actionButtons={
          <Link href='/services/new' underlineHover={false}>
            <Button icon={Add}>Add new AWS Service</Button>
          </Link>
        }
      />
      <Text>AWS Services are AWS services.</Text>

      <ServicesList services={services} passFunction={passFunction} />
      <Modal
        visible={open}
        onClose={handleClose}
        title={
          'Are you sure you want to delete ' +
          (currentService.name ? currentService.name : '') +
          '?'
        }
      >
        <Inline>
          <Button onClick={deleteCurrentService} variant='normal'>
            Yes
          </Button>
          <Button onClick={handleClose} variant='primary'>
            No
          </Button>
        </Inline>
      </Modal>
    </Stack>
  )
}

export default ManageServicesPage
