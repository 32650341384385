import { Box, Button, Container, Spinner } from '@awsui/components-react'
import React from 'react'
import Logo from './logon-logo.png'

const Authentication = (props) => {
  const handleLogon = () => {
    props.onSignIn()
  }

  const showSpinner = props.isAuthenticating || props.isLoadingState
  const showSignInButton = !props.isAuthenticating && !props.isLoadingState

  return (
    <Container className='custom-auth__box'>
      <Box textAlign='center' variant='h1'>
        <div>
          <img src={Logo} width={100} height={50} alt='' />
        </div>
        <div>
          <Box>AWS LaunchPad</Box>
        </div>
        {showSignInButton && (
          <div>
            <Button variant='primary' onClick={handleLogon}>
              Log In
            </Button>
          </div>
        )}
        {showSpinner && <Spinner size='large' />}
      </Box>
    </Container>
  )
}

export default Authentication
