import React from 'react'
import AppLayout from 'aws-northstar/layouts/AppLayout'
import SiteNavbar from 'components/common/SiteNavbar'
import SiteMenu from 'components/common/SiteMenu'
import Popover from 'aws-northstar/components/Popover'
import Button from 'aws-northstar/components/Button'
import Text from 'aws-northstar/components/Text'
import Link from 'aws-northstar/components/Link'
import Heading from 'aws-northstar/components/Heading'
import HelpPanel from 'aws-northstar/components/HelpPanel'
import styles from './BaseLayout.module.scss'
import HelpOutline from '@material-ui/icons/HelpOutline'

// :: ---
const learnMoreLinks = [
  <Link
    href='https://w.amazon.com/bin/view/ASEAN_Solutions_Architecture/Launchpad'
    target='_blank'
    key='0'
  >
    LaunchPad Wiki
  </Link>,
  <Link
    href='mailto:launchpad-dev@amazon.com'
    forceExternal='true'
    target='_blank'
    key='1'
  >
    Contact us
  </Link>,
]

function BaseLayout({ children }) {
  return (
    <AppLayout header={<SiteNavbar />} navigation={<SiteMenu />}>
      {children}
      <Popover
        className={styles.help}
        position='top'
        size='medium'
        triggerType='custom'
        variant='hover'
        content={
          <HelpPanel
            header={
              <h3>
                <b>What is LaunchPad?</b>
              </h3>
            }
            learnMoreFooter={learnMoreLinks}
          >
            <Text variant='p'>
              LaunchPad is a one-click demo repository for users to access
              visual content for customer conversations.
            </Text>
            <br />
            <Heading variant='h4'>Customer References</Heading>
            <Text variant='p'>
              Make use of live demos during the{' '}
              <i>Technical Validation Stage of Sales Cycle</i> to aid your
              customer.
            </Text>
            <br />
            <Heading variant='h4'>
              Thought Leadership Contribution (Build)
            </Heading>
            <Text variant='p'>
              After building your demo, log your efforts under Thought
              Leadership Contribution (Build) Goals by following the
              instructions{' '}
              <a
                className={styles.helplinks}
                href='https://w.amazon.com/bin/view/ASEAN_Solutions_Architecture/2021_ASEAN_SA_Goals#HDataRecording-4'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </Text>
          </HelpPanel>
        }
      >
        <Button icon={HelpOutline} variant='primary' className={styles.help}>
          Help
        </Button>
      </Popover>
    </AppLayout>
  )
}

export default BaseLayout
