import React, { useState, useCallback, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { useHistory } from 'react-router-dom'

import Button from 'aws-northstar/components/Button'
import Container from 'aws-northstar/layouts/Container'
import Input from 'aws-northstar/components/Input'
import TextArea from 'aws-northstar/components/Textarea'
import Stack from 'aws-northstar/layouts/Stack'
import Inline from 'aws-northstar/layouts/Inline'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'

import { Industry } from 'models'

// :: ---

function EditIndustryPage({ match }) {
  const history = useHistory()
  const [original, setOriginal] = useState(null)
  const [industry, setIndustry] = useState({
    name: '',
    description: '',
  })
  const [id, setId] = useState('')

  useEffect(() => {
    if (match.params.id) {
      console.log(match.params.id)
      setId(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    const fetchIndustry = async () => {
      const result = (await DataStore.query(Industry, (p) => p.id('eq', id)))[0]
      console.debug('Industry loaded:', result)
      setOriginal(result)
      setIndustry(result)
    }
    fetchIndustry()
  }, [id])

  const handleFormChange = useCallback((name, value) => {
    setIndustry((industry) => ({ ...industry, [name]: value }))
  }, [])
  const handleSaveModel = useCallback(async (industry, original) => {
    // :: TODO validation
    console.debug('Saving industry ...', industry)
    const response = await DataStore.save(
      Industry.copyOf(original, (updated) => {
        updated.name = industry.name
        updated.description = industry.description
      })
    )
    console.debug('Industry saved:', response)
    history.push('/industries')
  }, [])

  // :: ---
  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Edit an Industry'
        actionButtons={
          <Inline>
            <Button
              variant='primary'
              onClick={() => handleSaveModel(industry, original)}
            >
              Save Industry
            </Button>
            <Button variant='normal' onClick={() => history.goBack()}>
              Discard Changes
            </Button>
          </Inline>
        }
      />

      <Container headingVariant='h4' title='Industry Name'>
        <Input
          placeholder='Healthcare'
          type='text'
          name='name'
          onChange={(value) => handleFormChange('name', value)}
          value={industry ? industry.name : ''}
        />
      </Container>

      <Container headingVariant='h4' title='Description'>
        <TextArea
          name='description'
          onChange={(e) => handleFormChange('description', e.target.value)}
          value={industry ? industry.description : ''}
        />
      </Container>
    </Stack>
  )
}

export default EditIndustryPage
