import React, { useState, useCallback, useEffect } from 'react'
import Inline from 'aws-northstar/layouts/Inline'
import Button from 'aws-northstar/components/Button'
import Modal from 'aws-northstar/components/Modal'
import { DataStore } from '@aws-amplify/datastore'
import { Project } from 'models'
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash'
import Delete from '@material-ui/icons/Delete'

function deleteDemoPage({ demo }) {
  const [model, setModel] = useState(demo)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [restoreVisible, setRestoreVisible] = useState(false)

  useEffect(() => {
    setModel(demo)
  }, [demo])

  const handleCancel = () => {
    setDeleteVisible(false)
  }

  const handleHide = useCallback(async (model, bool) => {
    // ??? return success/fail before Module close
    await DataStore.save(
      Project.copyOf(model, (upd) => {
        upd.hide = bool
      })
    )
    setDeleteVisible(false)
    setRestoreVisible(false)
    // TODO model refresh in DemoDetailsPage on save
  }, [])

  return (
    <>
      {!!model && model.hide ? (
        <Button
          icon={RestoreFromTrash}
          onClick={() => {
            setRestoreVisible(true)
          }}
        >
          Restore
        </Button>
      ) : (
        <Button
          icon={Delete}
          onClick={() => {
            setDeleteVisible(true)
          }}
        >
          Remove
        </Button>
      )}

      {deleteVisible ? (
        <Modal
          title={'Are you sure you want to delete ' + model.name + '?'}
          visible={deleteVisible}
          onClose={() => setDeleteVisible(false)}
          footer={
            <Inline>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button variant='primary' onClick={() => handleHide(model, true)}>
                Delete
              </Button>
            </Inline>
          }
        ></Modal>
      ) : (
        <></>
      )}
      {restoreVisible ? (
        <Modal
          title={'Are you sure you want to restore ' + model.name + '?'}
          visible={restoreVisible}
          onClose={() => setRestoreVisible(false)}
          footer={
            <Inline>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button
                variant='primary'
                onClick={() => handleHide(model, false)}
              >
                Restore
              </Button>
            </Inline>
          }
        ></Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default deleteDemoPage
