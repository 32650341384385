import React, { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'

import Button from 'aws-northstar/components/Button'
import Stack from 'aws-northstar/layouts/Stack'
import Text from 'aws-northstar/components/Text'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'
import Modal from 'aws-northstar/components/Modal'
import Inline from 'aws-northstar/layouts/Inline'
import Link from 'aws-northstar/components/Link'
import Add from '@material-ui/icons/Add'

import TagsList from './TagsList/TagsList'

import { Tag } from 'models'

// :: ---

function ManageTagsPage() {
  const [tags, setTags] = useState([])
  const [currentTag, setCurrentTag] = useState('')
  const [open, setOpen] = useState(false)

  const fetchData = async () => {
    const tags = await DataStore.query(Tag)
    console.log(tags)
    setTags(tags)
  }
  // :: establish data connection to tags
  useEffect(() => {
    fetchData()
  }, [])

  const passFunction = async (industry) => {
    setOpen(true)
    setCurrentTag(industry)
  }
  const handleClose = () => {
    setOpen(false)
    setCurrentTag('')
  }
  const deleteCurrentTag = async () => {
    const result = await DataStore.delete(currentTag)
    console.log(result)
    fetchData()
    setOpen(false)
  }

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Manage Technology Domains'
        actionButtons={
          <Link href='/tags/new' underlineHover={false}>
            <Button icon={Add}>Add new Technology Domain</Button>
          </Link>
        }
      />
      <Text>
        Technology Domains provide primary categorization to demo listings.
      </Text>

      <TagsList tags={tags} passFunction={passFunction} />

      <Modal
        visible={open}
        onClose={handleClose}
        subtitle={
          'Are you sure you want to delete ' +
          (currentTag.name ? currentTag.name : '') +
          '?'
        }
      >
        <Inline>
          <Button onClick={deleteCurrentTag} variant='normal'>
            Yes
          </Button>
          <Button onClick={handleClose} variant='primary'>
            No
          </Button>
        </Inline>
      </Modal>
    </Stack>
  )
}

export default ManageTagsPage
