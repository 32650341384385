import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useDemoIndustries,
  useDemoCloudServices,
  useDemoTechnicalDomains,
} from 'hooks/demoHooks'
import TagList from 'components/common/TagList'

import styles from './DemoInformationTabPane.module.scss'
import { isEmpty } from 'lodash'
import { Storage } from '@aws-amplify/storage'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import Text from 'aws-northstar/components/Text'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'
import Stack from 'aws-northstar/layouts/Stack'

// :: ---

function DemoInformationSection({ title, children }) {
  return <KeyValuePair label={title} value={children}></KeyValuePair>
}

function DemoInformationTabPane({ demo }) {
  const { demoid } = useParams()
  const [industries] = useDemoIndustries(demoid)
  const [services] = useDemoCloudServices(demoid)
  const [domains] = useDemoTechnicalDomains(demoid)
  const [presignedUrls, setPresignedURLs] = useState([])
  useEffect(() => {
    async function fetchPresignedURLs() {
      const response = await Promise.all(
        demo.thumbnailKeys.map(async (k) => await Storage.get(k))
      )
      setPresignedURLs(response)
    }
    if (demo.thumbnailKeys !== null) {
      fetchPresignedURLs()
    }
  }, [])

  function wrapImageItem(val) {
    return (
      <figure className='image is-16by9'>
        <img className={styles.scaleAspectRatio} src={val}></img>
      </figure>
    )
  }

  // :: ---
  return (
    <Stack>
      {!isEmpty(demo.thumbnailKeys) ? (
        demo.thumbnailKeys.length > 1 ? (
          <Carousel
            infiniteLoop={true}
            useKeyboardArrows={true}
            showThumbs={false}
          >
            {presignedUrls.map((val, index) => (
              <div key={index}>{wrapImageItem(val)}</div>
            ))}
          </Carousel>
        ) : (
          wrapImageItem(presignedUrls[0])
        )
      ) : null}
      <Text variant='p'>{demo.description}</Text>
    </Stack>
  )
}
export default DemoInformationTabPane
