import React, { useReducer } from 'react'
import DemoContext from './demoContext'
import DemoReducer from './demoReducer'
import { SET_PROJECTS, SET_LOADING } from '../types'

import { DataStore } from '@aws-amplify/datastore'

import { Project } from 'models'

// Initialise all global states
const DemoState = (props) => {
  const initialState = {
    projects: [],
    loading: true,
  }

  // UseReducer Hook to dispatch / trigger Reducer to change states
  const [state, dispatch] = useReducer(DemoReducer, initialState)

  // Start of functions/actions declarations
  const setProjects = async (text) => {
    dispatch({
      type: SET_PROJECTS,
      payload: text,
    })
  }

  // TODO backfill to provide predicate?
  const retrieveProjects = async () => {
    const p = (await DataStore.query(Project)).filter((p) => !p.hide)
    dispatch({
      type: SET_PROJECTS,
      payload: p,
    })
  }
  const setLoading = (bool) => dispatch({ type: SET_LOADING, payload: bool })

  return (
    <DemoContext.Provider
      value={{
        projects: state.projects,
        loading: state.loading,
        setProjects,
        retrieveProjects,
        setLoading,
      }}
    >
      {props.children}
    </DemoContext.Provider>
  )
}
export default DemoState
