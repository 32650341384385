import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Hub } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { Analytics } from '@aws-amplify/analytics'
import { DataStore } from '@aws-amplify/datastore'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import HomePage from 'components/HomePage/HomePage'
import Authentication from 'components/common/Authentication'
import DemoDetailsPage from 'components/DemoDetailsPage/DemoDetailsPage'
import AddDemoPage from 'components/AddDemoPage/AddDemoPage'

import ManageTagsPage from 'components/ManageTagsPage/ManageTagsPage'
import ManageIndustriesPage from 'components/ManageIndustriesPage/ManageIndustriesPage'
import ManageServicesPage from 'components/ManageServicesPage/ManageServicesPage'

import AddTagsPage from 'components/AddTagsPage/AddTagsPage'
import AddIndustryPage from 'components/AddIndustryPage/AddIndustryPage'
import AddServicesPage from 'components/AddServicesPage/AddServicesPage'

import EditIndustryPage from 'components/EditIndustryPage/EditIndustryPage'
import EditTagsPage from 'components/EditTagsPage/EditTagsPage'
import EditServicesPage from 'components/EditServicesPage/EditServicesPage'

import BaseLayout from 'components/common/BaseLayout'

import PrivateRoute from 'components/common/PrivateRoute'

import FilterTagsPage from 'components/FilterTagsPage/FilterTagsPage'
import MyDemosPage from 'components/MyDemosPage/MyDemosPage'

import DemoState from 'context/demo/DemoState'
import NorthStarThemeProvider from 'aws-northstar/components/NorthStarThemeProvider'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

// :: ---

function AppRoot() {
  // // :: attempt to update user info cached in this app's datastore.
  // useEffect(() => {
  //   updateAuthenticatedUserCache()
  // }, [])
  const [isAuthenticated, setIAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        const {
          signInUserSession: {
            idToken: { payload },
          },
        } = data
        if (
          payload['cognito:groups'] &&
          payload['cognito:groups'].includes('admins')
        ) {
          setIsAdmin(true)
        }
        setIAuthenticated(true)
        //create pinpoint endpoint for the user's email address
        if (data.attributes.email) {
          const endpoint = {
            Address: data.attributes.email,
            channelType: 'EMAIL',
            optOut: 'NONE',
            UserAttributes: {
              email: [data.attributes.email],
              sub: [data.attributes.sub],
            },
          }
          if (window.location.hostname !== 'localhost') {
            Analytics.updateEndpoint(endpoint)
          }
        }
      })
      .catch(() => {
        setIAuthenticated(false)
        if (window.location.search) {
          setIsAuthenticating(true)
        }
      })
  }, [])
  Hub.listen('auth', (data) => {
    console.log(data.payload.event)
    switch (data.payload.event) {
      case 'signIn':
        console.debug(data.payload.event)
        setIAuthenticated(true)
        break
      case 'signIn_failure':
        console.debug(data.payload.event)
        setIAuthenticated(false)
        break
      case 'oAuthSignOut':
        console.log('CLEAR')
        DataStore.clear()
        // redirect to original page
        // setRequestedPage(data.payload.data)
        break

      default:
        break
    }
  })
  if (isAuthenticated) {
    return (
      <NorthStarThemeProvider
        fontFamily={[
          'Amazon Ember',
          'Helvetica Neue',
          'Helvetica',
          'Arial',
          'sans-serif',
        ]}
      >
        <div
          className='AppRoot'
          data-test='approot'
          style={{ backgroundColor: 'white' }}
        >
          <DemoState>
            <Helmet>
              <title>SA LaunchPad</title>
              <meta name='description' content='' />
            </Helmet>
            <Router>
              <BaseLayout>
                <Switch>
                  <Route exact path='/' component={HomePage} />
                  <Redirect from='/Tokens' to='/' />

                  <Route path='/demos/new' component={AddDemoPage} />
                  <Route path='/demos/:demoid' component={DemoDetailsPage} />
                  <Route path='/mydemos' component={MyDemosPage} />

                  <PrivateRoute
                    authed={isAdmin}
                    exact
                    path='/tags'
                    component={ManageTagsPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/tags/new'
                    component={AddTagsPage}
                  />
                  <Route
                    authed={isAdmin}
                    exact
                    path='/tags/:id'
                    component={FilterTagsPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/tags/edit/:id'
                    component={EditTagsPage}
                  />

                  <PrivateRoute
                    authed={isAdmin}
                    exact
                    path='/industries'
                    component={ManageIndustriesPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/industries/new'
                    component={AddIndustryPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/industries/:id'
                    component={FilterTagsPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/industries/edit/:id'
                    component={EditIndustryPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    exact
                    path='/services'
                    component={ManageServicesPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/services/new'
                    component={AddServicesPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/services/:id'
                    component={FilterTagsPage}
                  />
                  <PrivateRoute
                    authed={isAdmin}
                    path='/services/edit/:id'
                    component={EditServicesPage}
                  />

                  <Route component={HomePage} />
                </Switch>
              </BaseLayout>
            </Router>
          </DemoState>
        </div>
      </NorthStarThemeProvider>
    )
  } else {
    return (
      <div className='custom-auth__box__container'>
        <Authentication
          isAuthenticating={isAuthenticating}
          isLoadingState={isAuthenticated}
          onSignIn={() =>
            Auth.federatedSignIn({ customProvider: 'FederateOIDC' })
          }
        />
      </div>
    )
  }
}

export default AppRoot
