import React from 'react'
import { Link } from 'react-router-dom'

import image from '../../images/launchpadlogo.png'
import SiteNavbarAuthInfo from 'components/common/SiteNavbarAuthInfo'

import Header from 'aws-northstar/components/Header'
import Box from 'aws-northstar/layouts/Box'
import styles from './SiteNavbar.module.scss'
import Inline from 'aws-northstar/layouts/Inline'
// :: ---

function SiteNavbar() {
  const rightContent = (
    <Box display='flex' alignItems='center'>
      <Inline>
        <Link to='/' className={styles.homelink}>
          Home
        </Link>
        <Link to='/demos/new' className={styles.homelink}>
          Submit a Demo
        </Link>
        <a
          href='https://form.asana.com/?hash=6d5c359283ac6c0693891a2f139a5ca29029ba8286640818ce1a704237a66730&id=1182932372206184'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.homelink}
        >
          Send us feedback
        </a>
      </Inline>
      <SiteNavbarAuthInfo />
    </Box>
  )

  return (
    <Header
      title={
        <h2>
          <img src={image} className={styles.logo} />
        </h2>
      }
      rightContent={rightContent}
    />
    //   </div>
    // </>
  )
}

export default SiteNavbar
