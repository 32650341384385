import React, { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Auth } from '@aws-amplify/auth'
import { Analytics } from '@aws-amplify/analytics'
import santise, { sanitise } from '../../utils/sanitise'
import { ProjectComment } from 'models'
import Peccy from '../../images/peccy.jpg'
import CommentItem from './CommentItem'
import Button from 'aws-northstar/components/Button'
import Inline from 'aws-northstar/layouts/Inline'
import Stack from 'aws-northstar/layouts/Stack'
import Text from 'aws-northstar/components/Text'
import FormField from 'aws-northstar/components/FormField'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'
import Input from 'aws-northstar/components/Input'
import { CommentSharp } from '@material-ui/icons'

function DemoCommentsTabPane({ demo }) {
  useEffect(() => {
    fetchComments()
  }, [demo])
  const [model, setModel] = useState({ comment: '', owner: '' })
  const [comments, setComments] = useState([])
  // :: ---
  const changeModel = async () => {
    await fetchComments()
  }
  const fetchComments = async () => {
    console.debug('Fetching comments ...')
    const comments = await DataStore.query(ProjectComment, (comment) =>
      comment.projectID('eq', demo.id)
    )
    setComments(comments)
  }

  const handleUpdateForm = (name, value) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setModel((model) => ({ ...model, [name]: value, owner: user.username }))
      })
      .catch((err) => console.log(err))
  }

  const handleSaveComment = async () => {
    // ignore empty comments
    // TODO clear textbox and add edit options if user is owner
    // const original = await DataStore.query(, "123");
    if (model.comment.length > 0) {
      // console.log(model)
      const comment = new ProjectComment({
        content: model.comment,
        projectID: demo.id,
        owner: model.owner,
      })

      await DataStore.save(comment)
      await fetchComments()
      Analytics.record({
        name: 'demoComment',
        attributes: {
          type: 'demoComment',
          comment: model.comment,
          alias: model.owner,
          demoID: demo.id,
          name: demo.name,
          owner: demo.owner,
        },
      })
      setModel({})
    } else {
      return
    }
  }

  // :: ---

  return (
    <Stack>
      <FormField label='Add a Comment:' controlId='commentField'>
        <Stack>
          <Input
            placeholder='Leave a comment'
            type='text'
            onChange={(value) => handleUpdateForm('comment', sanitise(value))}
          />
          {/* <Textarea placeholder="Leave a comment!" controlId="formFieldId2" name="comment" onChange={(e)=>handleUpdateForm("comment", e.target.value)} /> */}
          <Button variant='secondary' onClick={handleSaveComment}>
            Submit
          </Button>
        </Stack>
      </FormField>
      <Text>
        {comments.map((c) => {
          return (
            <CommentItem key={c.id} comment={c} changeModel={changeModel} />
          )
        })}
      </Text>
    </Stack>
  )
}

export default DemoCommentsTabPane
