import React from 'react'
import { Link } from 'react-router-dom'
import Badge from 'aws-northstar/components/Badge'
import Inline from 'aws-northstar/layouts/Inline'

import styles from './TagList.module.scss'

// :: ---

function TagItem({ to, children, ...props }) {
  const tag = <Badge content={children} className={styles.tag} {...props} />

  return to ? <Link to={to}>{tag}</Link> : <>{tag}</>
}

function TagList({ items, selector, linker }) {
  const tags = items
    .filter((item) => !!item && !!item.id)
    .map((item) => (
      <TagItem key={item.id} to={linker(item)}>
        {selector(item)}
      </TagItem>
    ))

  return items.length == 0 ? '-' : <Inline spacing='xs'>{tags}</Inline>
}

export default TagList
