import React from 'react'
import ProjectList from 'components/common/ProjectList'

// :: ---

function HomePage() {
  return <ProjectList title={'One-Click Demos'} search={true} />
}

export default HomePage
