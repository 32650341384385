import React from 'react'
import Stack from 'aws-northstar/layouts/Stack'
import Container from 'aws-northstar/layouts/Container'
import Inline from 'aws-northstar/layouts/Inline'
import Button from 'aws-northstar/components/Button'
import Link from 'aws-northstar/components/Link'
import Edit from '@material-ui/icons/Edit'
import DeleteForever from '@material-ui/icons/DeleteForever'
// :: ---

function TagItem({ tag, passFunction }) {
  return (
    <Container
      title={tag.name}
      subtitle={tag.description}
      headingVariant='h4'
      gutters={false}
      actionGroup={
        <Inline>
          <Link href={'/tags/edit/' + tag.id} underlineHover={false}>
            <Button size='small' icon={Edit}>
              Edit Tech Domain
            </Button>
          </Link>

          <Button
            variant='secondary'
            size='small'
            onClick={() => passFunction(tag)}
            icon={DeleteForever}
          >
            Remove
          </Button>
        </Inline>
      }
    />
  )
}

function TagsList({ tags, passFunction }) {
  const items = tags.map((tag) => (
    <TagItem key={tag.name} tag={tag} passFunction={passFunction} />
  ))

  return <Stack spacing='xs'>{items}</Stack>
}

export default TagsList
