/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://d46xisxtybag3jrqhlpv5v6xtu.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-1:94068998-d82b-4742-9d88-c8b9e03a3272",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_zsqkMWJrj",
    "aws_user_pools_web_client_id": "6cdc0qb8aubpq2bg8km125if69",
    "oauth": {
        "domain": "webapp6cf2ba7c-6cf2ba7c-mvp.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.launchpad.sa.aws.dev/,https://relaunch.d2pp9pg6qtrral.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://www.launchpad.sa.aws.dev/,https://relaunch.d2pp9pg6qtrral.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "7b5f7add1ff9453fbab90c9f55c91664",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "7b5f7add1ff9453fbab90c9f55c91664",
            "region": "us-west-2"
        }
    },
    "aws_user_files_s3_bucket": "webapp4756995725094778b3b6b51f30999d0f172104-mvp",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
