import React from 'react'
import Stack from 'aws-northstar/layouts/Stack'
import Container from 'aws-northstar/layouts/Container'
import Inline from 'aws-northstar/layouts/Inline'
import Button from 'aws-northstar/components/Button'
import Link from 'aws-northstar/components/Link'
import Edit from '@material-ui/icons/Edit'
import DeleteForever from '@material-ui/icons/DeleteForever'

// :: ---

function ServiceItem({ service, passFunction }) {
  return (
    <Container
      title={service.name}
      subtitle={service.description}
      headingVariant='h4'
      gutters={false}
      actionGroup={
        <Inline>
          <Link href={'/services/edit/' + service.id} underlineHover={false}>
            <Button size='small' icon={Edit}>
              Edit Service
            </Button>
          </Link>

          <Button
            variant='secondary'
            size='small'
            onClick={() => passFunction(service)}
            icon={DeleteForever}
          >
            Remove
          </Button>
        </Inline>
      }
    />
  )
}

function ServicesList({ services, passFunction }) {
  const items = services.map((service) => (
    <ServiceItem
      key={service.id}
      service={service}
      passFunction={passFunction}
    />
  ))

  return <Stack spacing='xs'>{items}</Stack>
}

export default ServicesList
