import React, { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'

import Button from 'aws-northstar/components/Button'
import Stack from 'aws-northstar/layouts/Stack'
import Text from 'aws-northstar/components/Text'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'
import Modal from 'aws-northstar/components/Modal'
import Inline from 'aws-northstar/layouts/Inline'
import Link from 'aws-northstar/components/Link'

import IndustriesList from './IndustriesList'

import { Industry } from 'models'
import Add from '@material-ui/icons/Add'

// :: ---

function ManageIndustriesPage() {
  const [industries, setIndustries] = useState([])
  const [currentIndustry, setCurrentIndustry] = useState('')
  const [open, setOpen] = useState(false)

  const fetchIndustries = async () => {
    console.debug('Fetching Industries ...')
    const industries = await DataStore.query(Industry)
    console.debug('Industries:', industries)

    setIndustries(industries)
  }
  const passFunction = async (industry) => {
    setOpen(true)
    setCurrentIndustry(industry)
  }
  const handleClose = () => {
    setOpen(false)
    setCurrentIndustry('')
  }
  const deleteCurrentIndustry = async () => {
    const result = await DataStore.delete(currentIndustry)
    console.log(result)
    fetchIndustries()
    setOpen(false)
  }

  // :: ---
  useEffect(() => {
    fetchIndustries()
  }, [])

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Manage Industries'
        actionButtons={
          <Link href='/industries/new' underlineHover={false}>
            <Button icon={Add}>Add new Industry</Button>
          </Link>
        }
      />
      <Text>Industries are various market verticals.</Text>

      <IndustriesList industries={industries} passFunction={passFunction} />
      <Modal
        visible={open}
        onClose={handleClose}
        title={
          'Are you sure you want to delete ' +
          (currentIndustry.name ? currentIndustry.name : '') +
          '?'
        }
      >
        <Inline>
          <Button onClick={deleteCurrentIndustry} variant='normal'>
            Yes
          </Button>
          <Button onClick={handleClose} variant='primary'>
            No
          </Button>
        </Inline>
      </Modal>
    </Stack>
  )
}

export default ManageIndustriesPage
