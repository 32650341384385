import React, { useState, useCallback, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { useHistory } from 'react-router-dom'

import Button from 'aws-northstar/components/Button'
import Container from 'aws-northstar/layouts/Container'
import Input from 'aws-northstar/components/Input'
import TextArea from 'aws-northstar/components/Textarea'
import Stack from 'aws-northstar/layouts/Stack'
import Inline from 'aws-northstar/layouts/Inline'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'

import { Tag } from 'models'

// :: ---

function EditTagsPage({ match }) {
  const history = useHistory()
  const [original, setOriginal] = useState(null)
  const [tag, setTag] = useState({
    name: '',
    description: '',
  })
  const [id, setId] = useState('')

  useEffect(() => {
    if (match.params.id) {
      console.log(match.params.id)
      setId(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    const fetchTag = async () => {
      const result = (await DataStore.query(Tag, (p) => p.id('eq', id)))[0]
      console.debug('Tag loaded:', result)
      setOriginal(result)
      setTag(result)
    }
    fetchTag()
  }, [id])

  const handleFormChange = useCallback((name, value) => {
    setTag((tag) => ({ ...tag, [name]: value }))
  }, [])
  const handleSaveModel = useCallback(async (tag, original) => {
    // :: TODO validation
    console.debug('Saving tag ...', tag)
    const response = await DataStore.save(
      Tag.copyOf(original, (updated) => {
        updated.name = tag.name
        updated.description = tag.description
      })
    )
    console.debug('Tag saved:', response)
    history.push('/tags')
  })

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Edit a Tech Domain Tag'
        actionButtons={
          <Inline>
            <Button
              variant='primary'
              onClick={() => handleSaveModel(tag, original)}
            >
              Save Tag
            </Button>
            <Button variant='normal' onClick={() => history.goBack()}>
              Discard Changes
            </Button>
          </Inline>
        }
      />

      <Container headingVariant='h4' title='Tag Name'>
        <Input
          placeholder='Containers'
          type='text'
          name='name'
          onChange={(value) => handleFormChange('name', value)}
          value={tag ? tag.name : ''}
        />
      </Container>

      <Container headingVariant='h4' title='Description'>
        <TextArea
          name='description'
          onChange={(e) => handleFormChange('description', e.target.value)}
          value={tag ? tag.description : ''}
        />
      </Container>
    </Stack>
  )
}

export default EditTagsPage
