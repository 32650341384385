import React from 'react'
import Stack from 'aws-northstar/layouts/Stack'
import Container from 'aws-northstar/layouts/Container'
import Inline from 'aws-northstar/layouts/Inline'
import Button from 'aws-northstar/components/Button'
import Link from 'aws-northstar/components/Link'
import Edit from '@material-ui/icons/Edit'
import DeleteForever from '@material-ui/icons/DeleteForever'
// :: ---

function IndustryItem({ industry, passFunction }) {
  return (
    <Container
      title={industry.name}
      subtitle={industry.description}
      headingVariant='h4'
      gutters={false}
      actionGroup={
        <Inline>
          <Link href={'/industries/edit/' + industry.id} underlineHover={false}>
            <Button size='small' icon={Edit}>
              Edit Industry
            </Button>
          </Link>

          <Button
            variant='secondary'
            size='small'
            onClick={() => passFunction(industry)}
            icon={DeleteForever}
          >
            Remove
          </Button>
        </Inline>
      }
    />
  )
}

function IndustriesList({ industries, passFunction }) {
  const items = industries.map((industry) => (
    <IndustryItem
      key={industry.id}
      industry={industry}
      passFunction={passFunction}
    />
  ))

  return <Stack spacing='xs'>{items}</Stack>
}

export default IndustriesList
