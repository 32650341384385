import React, { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Link } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { isEmpty } from 'lodash'
import { Analytics } from '@aws-amplify/analytics'
import SideNavigation, {
  SideNavigationItemType,
} from 'aws-northstar/components/SideNavigation'

import styles from 'components/DemoDetailsPage/DemoInformationTabPane.module.scss'
import { Tag } from 'models'

import Input from 'aws-northstar/components/Input'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'

// :: ---

const SiteMenuLink = (() => {
  const __render = ({ children, ...props }) => (
    <Link to='/' {...props}>
      {children}
    </Link>
  )

  const __SiteMenuLink = (props) => (
    <li>
      <Link {...props} render={__render} />
    </li>
  )

  return __SiteMenuLink
})()

function TagLink({ tag }) {
  return (
    <Link
      className='MuiTypography-root MuiLink-root MuiLink-underlineHover makeStyles-link-70 πStyles-noneDecorationHover-72 MuiTypography-body1 MuiTypography-colorPrimary'
      to={`/tags/${tag.id}`}
      onClick={() =>
        Analytics.record({
          name: 'domainClick',
          attributes: {
            type: 'domainClick',
            tagName: tag.name,
            tagID: tag.id,
          },
        })
      }
    >
      {tag.name}
    </Link>
  )
}

const section1 = {
  type: SideNavigationItemType.SECTION,
  text: 'General',
  expanded: true,
  items: [
    { type: SideNavigationItemType.LINK, text: 'Dashboard', href: '/' },
    { type: SideNavigationItemType.LINK, text: 'My Demos', href: '/mydemos' },
  ],
}

function SiteMenu() {
  const [tags, setTags] = useState([])
  // let ftags = tags
  const [ftags, setFTags] = useState([])
  const [domainSearch, setDomainSearch] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [navigationItems, setNavigationItems] = useState([section1])
  // :: establish live connection with available tags
  useEffect(() => {
    const fetchTags = async () => {
      const tags = await DataStore.query(Tag)
      setTags(tags)
      setFTags(tags)
    }
    fetchTags()

    // :: ---
    const subscription = DataStore.observe(Tag).subscribe((message) => {
      console.debug(message.model, message.opType, message.element)

      // :: TODO event types for CRUD
      if (message.opType === 'INSERT') {
        setTags((tags) => [...tags, message.element])
      }
    })

    return subscription.unsubscribe.bind(subscription)
  }, [])

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((data) => {
      const {
        signInUserSession: {
          idToken: { payload },
        },
      } = data
      console.debug({ payload })
      if (
        payload['cognito:groups'] &&
        payload['cognito:groups'].includes('admins')
      ) {
        setIsAdmin(true)
      }
    })
  })

  useEffect(() => {
    let newNavigationItems = [section1]
    let tags = {
      type: SideNavigationItemType.SECTION,
      text: 'Tags',
      items: ftags.map((tag) => ({
        type: SideNavigationItemType.LINK,
        text: tag.name,
        href: `/tags/${tag.id}`,
      })),
    }
    newNavigationItems.push(tags)
    if (isAdmin) {
      newNavigationItems.push({
        type: SideNavigationItemType.SECTION,
        text: 'Admin',
        items: [
          {
            type: SideNavigationItemType.LINK,
            text: 'Manage Industries',
            href: '/industries',
          },
          {
            type: SideNavigationItemType.LINK,
            text: 'Manage Tags',
            href: '/tags',
          },
          {
            type: SideNavigationItemType.LINK,
            text: 'Manage Services',
            href: '/services',
          },
        ],
      })
    }
    setNavigationItems(newNavigationItems)
  }, [isAdmin, ftags])

  const domainSearchChange = async (value) => {
    let domainSearchStr = value
    setDomainSearch(domainSearchStr)
    if (isEmpty(domainSearchStr)) {
      setFTags(tags)
    } else {
      setFTags(
        tags.filter((i) =>
          i.name.toLowerCase().includes(domainSearchStr.toLowerCase())
        )
      )
    }
  }
  return (
    <>
      <SideNavigation
        header={{
          href: '/',
          text: 'LaunchPad',
        }}
        items={navigationItems}
      />
    </>
  )
}

export default SiteMenu
