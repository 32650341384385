import React, { useState, useCallback, useEffect } from 'react'
import FormField from 'aws-northstar/components/FormField'
import Textarea from 'aws-northstar/components/Textarea'
import Input from 'aws-northstar/components/Input'
import Inline from 'aws-northstar/layouts/Inline'
import Button from 'aws-northstar/components/Button'
import Modal from 'aws-northstar/components/Modal'
import { DataStore } from '@aws-amplify/datastore'
import { Project } from 'models'
import Save from '@material-ui/icons/Save'
import Edit from '@material-ui/icons/Edit'
import Cancel from '@material-ui/icons/Cancel'
import Restore from '@material-ui/icons/Restore'

function EditDemoPage({ demo }) {
  const [model, setModel] = useState(null)
  const [orig, setOrig] = useState(null)
  const [editVisible, setEditVisible] = useState(false)

  useEffect(() => {
    setModel(demo)
    setOrig(demo)
  }, [demo])

  const handleFormChange = useCallback((name, value) => {
    //handle Validation here
    if (name == 'hours') {
      if (value == '') {
        value = 0
      }
      value = parseInt(value)
    }
    setModel((model) => ({ ...model, [name]: value }))
  }, [])

  const handleDiscard = () => {
    setModel(orig)
    setEditVisible(false)
  }

  const handleSave = useCallback(async (model, orig) => {
    let keys = [
      'name',
      'subtitle',
      'contributors',
      'hours',
      'url',
      'description',
    ]
    // ??? return success/fail before Module close
    await DataStore.save(
      Project.copyOf(orig, (upd) => {
        keys.forEach((k) => (upd[k] = model[k]))
      })
    )
    setEditVisible(false)
    // TODO model refresh in DemoDetailsPage on save
  }, [])

  return (
    <>
      <Button
        icon={Edit}
        onClick={() => {
          console.debug('clicked')
          setEditVisible(true)
        }}
      >
        Edit
      </Button>

      {editVisible ? (
        <Modal
          title={'Edit'}
          visible={editVisible}
          onClose={() => setEditVisible(false)}
          footer={
            <Inline>
              <Button icon={Save} onClick={() => handleSave(model, orig)}>
                Save
              </Button>
              <Button icon={Cancel} onClick={() => handleDiscard()}>
                Discard
              </Button>
            </Inline>
          }
        >
          <FormField
            label='Demo Name'
            controlId='demoNameField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() => setModel({ ...model, name: orig.name })}
              />
            }
          >
            <Input
              required
              type='text'
              value={model ? model.name : ''}
              name='name'
              onChange={(value) => handleFormChange('name', value)}
            />
          </FormField>

          <FormField
            label='Contributor(s)'
            controlId='contributorsField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() =>
                  setModel({ ...model, contributors: orig.contributors })
                }
              />
            }
          >
            <Input
              type='text'
              value={model ? model.contributors : ''}
              name='contributors'
              onChange={(value) => handleFormChange('contributors', value)}
            />
          </FormField>

          <FormField
            label='Subtitle'
            controlId='subtitleField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() => setModel({ ...model, subtitle: orig.subtitle })}
              />
            }
          >
            <Input
              required
              type='text'
              value={model ? model.subtitle : ''}
              name='subtitle'
              onChange={(value) => handleFormChange('subtitle', value)}
            />
          </FormField>

          <FormField
            label='Description'
            controlId='descriptionField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() =>
                  setModel({ ...model, description: orig.description })
                }
              />
            }
          >
            <Textarea
              name={model.subtitle}
              value={model ? model.description : ''}
              onChange={(e) => handleFormChange('description', e.target.value)}
            />
          </FormField>

          <FormField
            label='Hours Spent'
            controlId='hoursField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() => setModel({ ...model, hours: orig.hours })}
              />
            }
          >
            <Input
              required
              type='number'
              value={model ? model.hours : '0'}
              name='hours'
              onChange={(value) => handleFormChange('hours', value)}
            />
          </FormField>

          <FormField
            label='Demo URL'
            controlId='urlField'
            secondaryControl={
              <Button
                variant='icon'
                icon={Restore}
                onClick={() => setModel({ ...model, url: orig.url })}
              />
            }
          >
            <Input
              required
              type='text'
              value={model ? model.url : ''}
              name='url'
              onChange={(value) => handleFormChange('url', value)}
            />
          </FormField>

          {/* TODO TAG EDIT */}
          {/* TODO Modify Images */}
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}

export default EditDemoPage
