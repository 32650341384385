import React, { useContext, useEffect, useState } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Analytics } from '@aws-amplify/analytics'
import { useHistory } from 'react-router-dom'

import ProjectList from 'components/common/ProjectList'
import DemoContext from '../../context/demo/demoContext'
import {
  Project,
  ProjectTag,
  ProjectIndustry,
  ProjectCloudService,
  Tag,
  Industry,
  CloudService,
} from 'models'

// :: ---

function FilterTagsPage({ match }) {
  const history = useHistory()
  const [id, setId] = useState('')
  const [type, setType] = useState({ t: '' })
  var array = []
  var arr = []
  const demoContext = useContext(DemoContext)
  useEffect(() => {
    if (match.params.id) {
      setId(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    const fetchTag = async () => {
      let input = ''
      let type = ''
      switch (match.path) {
        case '/industries/:id':
          input = ProjectIndustry
          type = 'industry'
          setType({ t: Industry })
          break
        case '/tags/:id':
          input = ProjectTag
          type = 'tag'
          setType({ t: Tag })
          break
        case '/services/:id':
          input = ProjectCloudService
          type = 'service'
          setType({ t: CloudService })
          break
        default:
          history.push('/')
      }
      ;(await DataStore.query(input))
        .filter((p) => !!p[type] && p[type].id === id)
        .forEach((proj) => {
          if (proj.project) {
            array.push(proj.project.id)
          }
        })
      if (array.length === 0) arr.push('0 Demos Found')
      else {
        for (var i = 0; i < array.length; i++) {
          const p = await DataStore.query(Project, array[i])
          arr.push(p)
        }
      }
      demoContext.setProjects(arr)
    }
    fetchTag()
  }, [id])

  useEffect(() => {
    const analytics = async () => {
      const tag = await DataStore.query(type.t, id)
      if (tag) {
        Analytics.record({
          name: 'domainClick',
          attributes: {
            type: 'domainClick',
            tagName: tag.name,
            tagID: tag.id,
          },
        })
      }
    }
    if (id !== '' && type.t !== '') {
      analytics()
    }
  }, [type, id])

  return <ProjectList title={'One-Click Demos'} search={false} />
}

export default FilterTagsPage
