import React, { useEffect, useState } from 'react'
import { Auth } from '@aws-amplify/auth'

import Button from 'aws-northstar/components/Button'
import styles from './SiteNavbarAuthInfo.module.scss'
import ExitToApp from '@material-ui/icons/ExitToApp'

const signOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

function SiteNavbarAuthInfo() {
  const [firstName, setFirstName] = useState('')
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setFirstName(user.attributes.given_name)
      })
      .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <div className='navbar-item'>
        <span className={styles.welcome}>
          <strong className='has-text-primary'>Welcome,</strong> {firstName}
        </span>
      </div>
      <div className='navbar-item'>
        <Button
          onClick={signOut}
          variant='primary'
          icon={ExitToApp}
          iconAlign='right'
        >
          Sign Out
        </Button>
      </div>
    </>
  )
}

export default SiteNavbarAuthInfo
