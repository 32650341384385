import React, { useState, useEffect, useCallback } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Analytics } from '@aws-amplify/analytics'
import { useHistory } from 'react-router-dom'
import { sanitise } from '../../utils/sanitise'
import {
  Project,
  ProjectTag,
  Tag,
  ProjectIndustry,
  Industry,
  ProjectCloudService,
  CloudService,
} from 'models'

import { Storage } from '@aws-amplify/storage'
import { v4 as uuid } from 'uuid'

import styles from './AddDemoPage.module.scss'
import FormSection from 'aws-northstar/components/FormSection'
import FormField from 'aws-northstar/components/FormField'
import Button from 'aws-northstar/components/Button'
import Input from 'aws-northstar/components/Input'
import Textarea from 'aws-northstar/components/Textarea'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'
import FileUpload from 'aws-northstar/components/FileUpload'
import Checkbox from 'aws-northstar/components/Checkbox'
import Alert from 'aws-northstar/components/Alert'
import Link from 'aws-northstar/components/Link'
import Box from 'aws-northstar/layouts/Box'
import Inline from 'aws-northstar/layouts/Inline'
import Stack from 'aws-northstar/layouts/Stack'

// :: ---
//TODO ADD HOURS TO ANALYTICS
// :: Convenience wrapper for a selectable Tag

function TagItem({ tag, selected, ...props }) {
  return (
    <Box
      color={selected ? 'secondary.contrastText' : 'primary.contrastText'}
      bgcolor={selected ? 'secondary.main' : 'primary.main'}
      padding='0.1em 0.5em'
      borderRadius='0.3em'
      {...props}
    >
      {tag.name}
    </Box>
  )
}
function removePropFromObject(obj, prop) {
  const { [prop]: _, ...rest } = obj
  return { ...rest }
}
function AddDemoPage() {
  const history = useHistory()
  const [model, setModel] = useState({
    name: '',
    contributors: '',
    subtitle: '',
    description: '',
    url: '',
    hours: 0,
    tags: [],
    like: 0,
    dislike: 0,
    hide: false,
  })

  const [tags, setTags] = useState({})
  const [industries, setIndustries] = useState({})
  const [services, setServices] = useState({})
  const [thumbnails, setThumbnails] = useState([])

  const [visibleIndustries, setVisibleIndustries] = useState([])
  const [visibleServices, setVisibleServices] = useState([])
  const [visibleTags, setVisibleTags] = useState([])

  const [searchValue, setSearchValue] = useState('')
  const [checked, setChecked] = useState({
    condition1: false,
    condition2: false,
  })

  // :: Gets called whenever something on the form changes.
  //    Use this to update the form state / model.

  const handleFormChange = useCallback((name, value) => {
    // let { name, value } = target
    //handle Validation here
    const cleanVal = sanitise(value)
    if (name == 'hours') {
      if (value == '') {
        value = 0
      }
      value = parseInt(cleanVal)
    } else {
      value = cleanVal
    }
    setModel((model) => ({ ...model, [name]: value }))
  }, [])
  const handleTagSelect = useCallback(({ target }, tag) => {
    setTags((prevState) => {
      if (!Object.prototype.hasOwnProperty.call(prevState, tag.id)) {
        return { ...prevState, [tag.id]: tag }
      }
      return removePropFromObject(prevState, tag.id)
    })
  }, [])

  const handleIndustrySelect = useCallback(({ target }, industry) => {
    setIndustries((prevState) => {
      if (!Object.prototype.hasOwnProperty.call(prevState, industry.id)) {
        return { ...prevState, [industry.id]: industry }
      }
      return removePropFromObject(prevState, industry.id)
    })
  }, [])

  const handleServiceSelect = useCallback(({ target }, service) => {
    setServices((prevState) => {
      if (!Object.prototype.hasOwnProperty.call(prevState, service.id)) {
        return { ...prevState, [service.id]: service }
      }
      return removePropFromObject(prevState, service.id)
    })
  }, [])

  const handleUploadSelect = async (files) => {
    setThumbnails(files)
  }
  const uploadFile = async (file) => {
    try {
      // attach file extension to help browser image file-type guessing
      let fileExt = file.name.split('.').pop()
      let objectKey = ''
      do {
        // check key to prevent overwrite
        objectKey = uuid() + '.' + fileExt
      } while ((await Storage.list(objectKey)).length > 0)

      const result = await Storage.put(objectKey, file, {
        contentType: file.type,
      })
      return result.key
    } catch (err) {
      console.warn(err)
    }
  }

  const handleSaveModel = async (event) => {
    Analytics.record({
      name: 'addDemo',
      attributes: {
        type: 'addDemo',
        name: model.name,
      },
    })
    event.preventDefault()
    // upload and get keyvals for the payload
    Promise.all(thumbnails.map((f) => uploadFile(f)))
      .then((keyVals) => {
        // :: TODO validation of values
        const payload = new Project({ ...model, thumbnailKeys: keyVals })
        return DataStore.save(payload)
      })
      .then((project) => {
        // :: save relationships
        const selectedTags = Object.values(tags)
        const selectedIndustries = Object.values(industries)
        const selectedServices = Object.values(services)

        const saveTagsTasks = selectedTags.map((tag) =>
          DataStore.save(
            new ProjectTag({
              project,
              tag,
            })
          )
        )

        const saveIndustriesTasks = selectedIndustries.map((industry) =>
          DataStore.save(new ProjectIndustry({ project, industry }))
        )
        const saveServicesTasks = selectedServices.map((service) =>
          DataStore.save(new ProjectCloudService({ project, service }))
        )

        Promise.all([
          ...saveTagsTasks,
          ...saveIndustriesTasks,
          ...saveServicesTasks,
        ]).then((response) => {
          console.log(response)
          history.push('/')
        })
      })
  }

  const fetchDomains = async () => {
    const domains = await DataStore.query(Tag)
    const tags = domains.map((domain) => ({ ...domain, selected: false }))
    // setTags(tags)
    setVisibleTags(tags)
  }

  const fetchIndustries = async () => {
    const industries = await DataStore.query(Industry)
    const i = industries.map((industry) => ({ ...industry, selected: false }))
    setVisibleIndustries(i)
  }

  const fetchServices = async () => {
    const services = await DataStore.query(CloudService)
    const s = services.map((service) => ({ ...service, selected: false }))
    setVisibleServices(s)
  }
  const searchFetch = async () => {
    const domains = (await DataStore.query(Tag)).filter((e) => {
      let searchValLC = searchValue.toLowerCase()
      return e.name.toLowerCase().includes(searchValLC)
    })
    const industries = (await DataStore.query(Industry)).filter((e) => {
      let searchValLC = searchValue.toLowerCase()
      return e.name.toLowerCase().includes(searchValLC)
    })
    const services = (await DataStore.query(CloudService)).filter((e) => {
      let searchValLC = searchValue.toLowerCase()
      return e.name.toLowerCase().includes(searchValLC)
    })
    setVisibleTags(domains)
    setVisibleIndustries(industries)
    setVisibleServices(services)
  }

  // :: ---

  // :: fetch available tags
  useEffect(() => {
    fetchDomains()
    fetchIndustries()
    fetchServices()
  }, [])
  useEffect(async () => {
    searchFetch()
  }, [searchValue])

  return (
    <form onSubmit={handleSaveModel}>
      <FormSection
        header='Add a New Demo'
        description='Fill in information on your demo'
      >
        <FormField
          label='Demo Name'
          controlId='demoNameField'
          hintText='Make sure your demo name is descriptive.'
        >
          <Input
            required
            type='text'
            placeholder='Demo Name'
            name='name'
            onChange={(value) => handleFormChange('name', value)}
          />
        </FormField>

        <FormField label='Contributor(s)' controlId='contributorsField'>
          <Input
            type='text'
            placeholder='alias1@, alias2@'
            name='contributors'
            onChange={(value) => handleFormChange('contributors', value)}
          />
        </FormField>

        <FormField
          label='Subtitle'
          controlId='subtitleField'
          hintText='Think of this as a short marketing blurb.'
        >
          <Input
            required
            type='text'
            placeholder='Simple, one-day workshop content for containers, serverless, and the AWS CDK.'
            name='subtitle'
            onChange={(value) => handleFormChange('subtitle', value)}
          />
        </FormField>

        <FormField label='Description' controlId='descriptionField'>
          <Textarea
            name='description'
            onChange={(e) => handleFormChange('description', e.target.value)}
          />
        </FormField>

        <FormField
          label='Hours Spent'
          controlId='hoursField'
          hintText='How long did you spend building this demo?'
        >
          <Input
            required
            type='number'
            placeholder='0'
            name='hours'
            onChange={(value) => handleFormChange('hours', value)}
          />
        </FormField>

        <FormField
          label='Demo URL'
          controlId='urlField'
          hintText='Where can we see your demo? A website or a code repository works.'
        >
          <Input
            required
            type='text'
            placeholder='https://demo-url.com'
            name='url'
            onChange={(value) => handleFormChange('url', value)}
          />
        </FormField>
        <FileUpload
          controlId='demo-image-upload'
          label='Upload Architecture Diagram(s)'
          accept='image/*'
          multiple={true}
          onChange={(files) => handleUploadSelect(files)}
        ></FileUpload>
      </FormSection>
      <FormSection
        header='Tag Selection'
        description='Add tags to give users a high-level overview of your demo'
      >
        <FormField
          label='Filter Tags'
          controlId='filterField'
          hintText='Filter the tags displayed.'
        >
          <Input
            type='search'
            placeholder=''
            name='name'
            onChange={(value) => setSearchValue(value)}
          />
        </FormField>

        <Stack>
          <KeyValuePair
            label='Technical Domains'
            value={
              <Inline spacing='xs'>
                {visibleTags.map((tag) => (
                  <TagItem
                    key={tag.id}
                    tag={tag}
                    selected={!(tags[tag.id] === undefined)}
                    onClick={(event) => handleTagSelect(event, tag)}
                  />
                ))}
              </Inline>
            }
          ></KeyValuePair>

          <KeyValuePair
            label='Industry Verticals'
            value={
              <Inline spacing='xs'>
                {visibleIndustries.map((industry) => (
                  <TagItem
                    key={industry.id}
                    tag={industry}
                    selected={!(industries[industry.id] === undefined)}
                    onClick={(event) => handleIndustrySelect(event, industry)}
                  />
                ))}
              </Inline>
            }
          ></KeyValuePair>

          <KeyValuePair
            label='Primary AWS Services Used'
            value={
              <Inline spacing='xs'>
                {visibleServices.map((service) => (
                  <TagItem
                    key={service.id}
                    tag={service}
                    selected={!(services[service.id] === undefined)}
                    onClick={(event) => handleServiceSelect(event, service)}
                  />
                ))}
              </Inline>
            }
          ></KeyValuePair>
        </Stack>
      </FormSection>
      <Stack>
        <Alert
          type='info'
          header='Add Demo to Thought Leadership Contribution (Build)'
        >
          <p>
            You can log your efforts towards Thought Leadership Contribution
            (Build) by following these{' '}
            <Link
              href='https://w.amazon.com/bin/view/ASEAN_Solutions_Architecture/2021_ASEAN_SA_Goals#HDataRecording-4'
              target='_blank'
            >
              instructions
            </Link>
          </p>
        </Alert>
        <Checkbox
          description={
            <span className={styles.guidelines}>
              Guidelines can be found{' '}
              <Link
                href='https://w.amazon.com/bin/view/AWS/WWRO/Security%26Governance/Campaigns/NAWSAgent/CapacityOwners'
                target='_blank'
              >
                here
              </Link>
            </span>
          }
          onChange={(e) =>
            setChecked({ ...checked, condition1: e.target.checked })
          }
        >
          I confirm my demo and source code follows security and governance
          guidelines
        </Checkbox>
        <Checkbox
          onChange={(e) =>
            setChecked({ ...checked, condition2: e.target.checked })
          }
        >
          I confirm my demo does not contain any AWS customer-specific
          information
        </Checkbox>
        <div>
          <Button variant='link' onClick={() => history.goBack()}>
            Discard and Cancel
          </Button>
          <Button
            variant='primary'
            type='submit'
            disabled={checked.condition1 && checked.condition2 ? false : true}
          >
            Submit Demo
          </Button>
        </div>
      </Stack>
    </form>
  )
}

export default AddDemoPage
