import React, { useState, useCallback, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { useHistory } from 'react-router-dom'

import Button from 'aws-northstar/components/Button'
import Container from 'aws-northstar/layouts/Container'
import Input from 'aws-northstar/components/Input'
import TextArea from 'aws-northstar/components/Textarea'
import Stack from 'aws-northstar/layouts/Stack'
import Inline from 'aws-northstar/layouts/Inline'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'

import { CloudService } from 'models'

// :: ---

function EditServicesPage({ match }) {
  const history = useHistory()
  const [original, setOriginal] = useState(null)
  const [service, setService] = useState({
    name: '',
    description: '',
  })
  const [id, setId] = useState('')

  useEffect(() => {
    if (match.params.id) {
      console.log(match.params.id)
      setId(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    const fetchService = async () => {
      const result = (
        await DataStore.query(CloudService, (p) => p.id('eq', id))
      )[0]
      console.debug('Service loaded:', result)
      setOriginal(result)
      setService(result)
    }
    fetchService()
  }, [id])

  const handleFormChange = useCallback((name, value) => {
    setService((service) => ({ ...service, [name]: value }))
  }, [])
  const handleSaveModel = useCallback(async (service, original) => {
    // :: TODO validation
    console.debug('Saving service ...', service)
    const response = await DataStore.save(
      CloudService.copyOf(original, (updated) => {
        updated.name = service.name
        updated.description = service.description
      })
    )
    console.debug('Service saved:', response)
    history.push('/services')
  })
  // :: ---

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Edit an AWS Service'
        actionButtons={
          <Inline>
            <Button
              variant='primary'
              onClick={() => handleSaveModel(service, original)}
            >
              Save AWS Service
            </Button>
            <Button variant='normal' onClick={() => history.goBack()}>
              Discard Changes
            </Button>
          </Inline>
        }
      />

      <Container headingVariant='h4' title='Service Name'>
        <Input
          placeholder='Amazon EKS'
          type='text'
          name='name'
          onChange={(value) => handleFormChange('name', value)}
          value={service ? service.name : ''}
        />
      </Container>

      <Container headingVariant='h4' title='Description'>
        <TextArea
          name='description'
          onChange={(e) => handleFormChange('description', e.target.value)}
          value={service ? service.description : ''}
        />
      </Container>
    </Stack>
  )
}

export default EditServicesPage
