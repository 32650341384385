import React, { useEffect, useCallback, useContext, useState } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Project } from 'models'

import Masonry from 'react-masonry-component'
import ProjectCard from 'components/common/ProjectCard'

import styles from './ProjectList.module.scss'
import DemoContext from 'context/demo/demoContext'
import { Pagination } from '@awsui/components-react'
import Select from 'aws-northstar/components/Select'
import Grid from 'aws-northstar/layouts/Grid'
import Container from 'aws-northstar/layouts/Container'
import Inline from 'aws-northstar/layouts/Inline'
import SearchInput from 'components/common/SearchInput'

// :: ---

const MASONRY_OPTIONS = {
  columnWidth: `.${styles.masonrywidthreference}`,
  gutter: `.${styles.masonrygutterreference}`,
}

// :: ---

function ProjectList({ title, search }) {
  const demoContext = useContext(DemoContext)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [selectedOption, setSeletedOption] = useState()

  var pageCount = Math.ceil(demoContext.projects.length / pageSize)

  const fetchProjects = useCallback(async () => {
    demoContext.retrieveProjects()
    setCount(pageCount)
  }, [pageCount])

  const handlePageChange = (event) => {
    window.scrollTo(0, 0)
    setPage(event.detail.currentPageIndex)
  }

  const handlePageSizeChange = (event) => {
    setSeletedOption(options.find((o) => o.value === event.target.value))
    setPageSize(event.target.value)
    setPage(1)
  }

  const options = [
    { label: '12', value: 12 },
    { label: '15', value: 15 },
    { label: '18', value: 18 },
  ]

  // :: initialize the projects in the local datastore
  // :: also, update the store when a change has been done in the store.
  useEffect(() => {
    fetchProjects()
    const subscription = DataStore.observe(Project).subscribe(fetchProjects)
    return () => subscription.unsubscribe()
  }, [])

  return (
    <Container
      headingVariant='h1'
      // title={title}
      style={{ boxShadow: '0 0 3px #859196' }}
      headerContent={
        <Inline>
          {search ? <SearchInput pageChange={() => setPage(1)} /> : null}
          Demos Per Page:
          <Select
            placeholder='12'
            options={options}
            selectedOption={selectedOption}
            onChange={handlePageSizeChange}
          />
        </Inline>
      }
    >
      <Grid container spacing={3} alignItems='stretch'>
        {demoContext &&
          demoContext.projects.map((project, index) =>
            Math.ceil((index + 1) / pageSize) === page ? (
              <Grid key={demoContext.projects.id} item xs={4} md={3} xl={2}>
                <ProjectCard key={project.id} project={project} />
              </Grid>
            ) : null
          )}
      </Grid>
      <div className={styles.pageNumberDiv}>
        <Pagination
          pagesCount={pageCount}
          currentPageIndex={page}
          onChange={handlePageChange}
          className={styles.pageNumber}
        />
      </div>
    </Container>
  )
}

export default ProjectList
