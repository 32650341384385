import React from 'react'
import ReactDOM from 'react-dom'

import { Amplify } from '@aws-amplify/core'
import amplifyconfig from 'aws-exports'

import AppRoot from 'components/AppRoot'

import * as serviceWorker from './serviceWorker'
import './index.scss'

// Amplify.Logger.LOG_LEVEL = "DEBUG"

// :: ---

const ROOT_ELEMENT_ID = 'root'

// Assuming you have 3 redirect URIs, and the first is for localhost, second is for production, third for development
const [localRedirectSignIn, productionRedirectSignIn, devRedirectSignIn] =
  amplifyconfig.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, productionRedirectSignOut, devRedirectSignOut] =
  amplifyconfig.oauth.redirectSignOut.split(',')

let oauthSignInURL = ''
let oauthSignOutURL = ''

switch (window.location.hostname) {
  case 'localhost':
  case '[::1]': // [::1] is the IPv6 localhost address.
  case (
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) || {}
  ).input: // 127.0.0.1/8 is considered localhost for IPv4.
    oauthSignInURL = localRedirectSignIn
    oauthSignOutURL = localRedirectSignOut
    break
  case 'www.launchpad.sa.aws.dev':
    var console = {}
    console.log = function () {}
    oauthSignInURL = productionRedirectSignIn
    oauthSignOutURL = productionRedirectSignOut
    break
  case 'relaunch.d2pp9pg6qtrral.amplifyapp.com':
    oauthSignInURL = devRedirectSignIn
    oauthSignOutURL = devRedirectSignOut
    break
  default:
    oauthSignInURL = localRedirectSignIn
    oauthSignOutURL = localRedirectSignOut
}

const updatedAwsConfig = {
  ...amplifyconfig,
  oauth: {
    ...amplifyconfig.oauth,
    redirectSignIn: oauthSignInURL,
    redirectSignOut: oauthSignOutURL,
  },
}

Amplify.configure(updatedAwsConfig)

ReactDOM.render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>,
  document.getElementById(ROOT_ELEMENT_ID)
)

// :: If you want your app to work offline and load faster,
//    you can change unregister() to register() below.
//    Note that this comes with some pitfalls.
//
//    Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
