import { useState, useEffect } from 'react'
import { DataStore } from '@aws-amplify/datastore'

import { ProjectIndustry, ProjectCloudService, ProjectTag } from 'models'

// :: ---

export function useDemoIndustries(demoid) {
  const [industries, setIndustries] = useState([])

  const fetchIndustries = async () => {
    console.debug('Fetching industries for demo:', demoid)
    const industries = (await DataStore.query(ProjectIndustry))
      .filter((item) => !!item.project && item.project.id === demoid)
      .map(({ industry }) => industry)

    setIndustries(industries)
    console.debug('Industries retrieved:', industries)
  }

  useEffect(() => {
    fetchIndustries()
  }, [demoid])

  return [industries, fetchIndustries]
}

export function useDemoCloudServices(demoid) {
  const [services, setServices] = useState([])

  const fetchCloudServices = async () => {
    console.debug('Fetching cloud services for demo:', demoid)
    const services = (await DataStore.query(ProjectCloudService))
      .filter((item) => !!item.project && item.project.id === demoid)
      .map(({ service }) => service)
    setServices(services)
    console.debug('Cloud Services retrieved:', services)
  }

  useEffect(() => {
    fetchCloudServices()
  }, [demoid])

  return [services, fetchCloudServices]
}

// :: FIXME rename Tag to TechnicalDomain
export function useDemoTechnicalDomains(demoid) {
  const [domains, setDomains] = useState([])

  const fetchDomains = async () => {
    console.debug('Fetching technical domains for demo:', demoid)
    const domains = (await DataStore.query(ProjectTag))
      .filter((item) => !!item.project && item.project.id === demoid)
      .map(({ tag }) => tag)

    setDomains(domains)
    console.debug('Technical Domains retrieved:', domains)
  }

  useEffect(() => {
    fetchDomains()
  }, [demoid])

  return [domains, fetchDomains]
}
