import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataStore } from '@aws-amplify/datastore'
import { Analytics } from '@aws-amplify/analytics'
import { Auth } from '@aws-amplify/auth'

import DemoInformationTabPane from './DemoInformationTabPane'
import DemoCommentsTabPane from './DemoCommentsTabPane'
import EditDemoPage from 'components/EditDemoPage/EditDemoPage'
import DeleteDemoPage from 'components/DeleteDemoPage/DeleteDemoPage'

import TagList from 'components/common/TagList'
import {
  useDemoIndustries,
  useDemoCloudServices,
  useDemoTechnicalDomains,
} from 'hooks/demoHooks'

import { Project, CustomerInfo } from 'models'

import Text from 'aws-northstar/components/Text'
import Link from 'aws-northstar/components/Link'
import Container from 'aws-northstar/layouts/Container'
import Modal from 'aws-northstar/components/Modal'
import FormField from 'aws-northstar/components/FormField'
import Input from 'aws-northstar/components/Input'
import Button from 'aws-northstar/components/Button'
import Tabs from 'aws-northstar/components/Tabs'
import Inline from 'aws-northstar/layouts/Inline'
import ColumnLayout, { Column } from 'aws-northstar/layouts/ColumnLayout'
import Stack from 'aws-northstar/layouts/Stack'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'
// import Icon from 'aws-northstar/components/Icon'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
// :: ---

function DemoDetailsPage() {
  const { demoid } = useParams()
  const [industries] = useDemoIndustries(demoid)
  const [services] = useDemoCloudServices(demoid)
  const [domains] = useDemoTechnicalDomains(demoid)
  const [demo, setDemo] = useState(null)
  const [buttonState, setButtonState] = useState(null)
  const [open, setOpen] = useState(false)

  const [customerInfo, setCustomerInfo] = useState({
    customerName: '',
    opportunityID: '',
    projectID: '',
    alias: '',
    email: '',
  })
  // Hide IDP prefix on front-end
  // TODO centralise this config
  const IDP_PREFIX = 'FederateOIDC_'
  const tabs = [
    {
      label: 'Information',
      id: 'first',
      content: <DemoInformationTabPane demo={demo} />,
    },
    {
      label: 'Comments',
      id: 'second',
      content: <DemoCommentsTabPane demo={demo} />,
    },
  ]
  const tagDisplay = [
    <TagList
      key={industries}
      items={industries}
      selector={(item) => item.name}
      linker={(item) => `/industries/${item.id}`}
    />,
    <TagList
      key={domains}
      items={domains}
      selector={(item) => item.name}
      linker={(item) => `/tags/${item.id}`}
    />,
    <TagList
      key={services}
      items={services}
      selector={(item) => item.name}
      linker={(item) => `/services/${item.id}`}
    />,
  ]
  const actionButtons = (
    <Inline>
      <Button
        icon={ArrowDropUp}
        iconAlign='left'
        onClick={() => (buttonState !== 'like' ? onLikeClick() : null)}
      >
        Upvote {demo ? demo.like : ''}
      </Button>
      <Button
        icon={ArrowDropDown}
        iconAlign='left'
        onClick={() => (buttonState !== 'dislike' ? onDislikeClick() : null)}
      >
        Downvote {demo ? demo.dislike : ''}
      </Button>
    </Inline>
  )

  const onUrlClick = async () => {
    Analytics.record({
      name: 'visitDemoSite',
      attributes: {
        type: 'visitDemoSite',
        demoID: demo.id,
        owner: demo.owner,
        name: demo.name,
        alias: customerInfo.alias,
        email: customerInfo.email,
      },
    })
    setOpen(true)
    setCustomerInfo({ ...customerInfo, projectID: demoid })
  }

  const handleClose = () => {
    setOpen(false)
    const url = demo.url
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const onSubmit = async () => {
    Analytics.record({
      name: 'custOppty',
      attributes: {
        type: 'custOppty',
        demoID: demo.id,
        owner: demo.owner,
        name: demo.name,
        customer: customerInfo.customerName, //Name of external customers that user is using the demo for
        opptyID: customerInfo.opportunityID,
        alias: customerInfo.alias, //Alias of internal user
        email: customerInfo.email,
      },
    })
    const payload = new CustomerInfo({ ...customerInfo, projectID: demoid })
    await DataStore.save(payload)
    handleClose()
  }

  const onLikeClick = async () => {
    const original = await DataStore.query(Project, `${demo.id}`)

    await DataStore.save(
      Project.copyOf(original, (updated) => {
        updated.like += 1
        updated.dislike =
          buttonState === 'dislike' ? updated.dislike - 1 : updated.dislike
        setDemo({
          ...demo,
          like: updated.like,
          dislike: updated.dislike,
        })
        setButtonState('like')
      })
    )
  }

  const onDislikeClick = async () => {
    const original = await DataStore.query(Project, `${demo.id}`)

    await DataStore.save(
      Project.copyOf(original, (updated) => {
        updated.like = buttonState === 'like' ? updated.like - 1 : updated.like
        updated.dislike += 1
        setDemo({
          ...demo,
          like: updated.like,
          dislike: updated.dislike,
        })
        setButtonState('dislike')
      })
    )
  }

  // :: retrieve demo
  useEffect(() => {
    const fetchDemo = async () => {
      const demo = (
        await DataStore.query(Project, (p) => p.id('eq', demoid))
      )[0]
      console.debug('Demo loaded:', demo)
      setDemo(demo)
    }
    fetchDemo()
  }, [demoid])
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCustomerInfo({
          ...customerInfo,
          alias: user.username,
          email: user.attributes.email,
        })
      })
      .catch((err) => console.log(err))
    if (demo != null) {
      // console.debug('triggered Analytics')
      Analytics.record({
        name: 'demoView',
        attributes: {
          type: 'demoView',
          demoID: demo.id,
          owner: demo.owner,
          name: demo.name,
          alias: customerInfo.alias,
          email: customerInfo.email,
        },
      })
    }
  }, [demo])
  // :: ---
  if (!demo) return <></>

  // :: ---

  function setTitle(demo) {
    console.log('demo hide', demo.hide)
    switch (demo.hide) {
      case false:
      case undefined:
      case null:
        return demo.name
      case true:
        return (
          <>
            {demo.name} <VisibilityOff /> <Text>[Deleted]</Text>
          </>
        )
    }
  }

  return (
    <Stack>
      <HeadingStripe
        title={setTitle(demo)}
        actionButtons={
          demo.owner == customerInfo.alias ? (
            <Inline>
              <EditDemoPage demo={demo} />
              <DeleteDemoPage demo={demo} />
            </Inline>
          ) : (
            []
          )
        }
      />
      <Text>{demo.subtitle}</Text>
      <Container
        title='Details'
        style={{ boxShadow: '0 0 3px #859196' }}
        actionGroup={actionButtons}
      >
        <ColumnLayout>
          <Column key='column1'>
            <Stack>
              <KeyValuePair
                label='Submitted By'
                value={demo.owner.split(IDP_PREFIX)[1]}
              ></KeyValuePair>
              <KeyValuePair
                label='Contributor(s)'
                value={demo.contributors}
              ></KeyValuePair>
            </Stack>
          </Column>
          <Column key='column2'>
            <Stack>
              <KeyValuePair
                label='Industries'
                value={tagDisplay[0]}
              ></KeyValuePair>
              <KeyValuePair
                label='Technical Domains'
                value={tagDisplay[1]}
              ></KeyValuePair>
              <KeyValuePair
                label='AWS Services Used'
                value={tagDisplay[2]}
              ></KeyValuePair>
            </Stack>
          </Column>
          <Column key='column3'>
            <KeyValuePair
              label='Demo Site'
              value={
                <Link forceExternal={true} onClick={() => onUrlClick()}>
                  Visit Demo Site
                </Link>
              }
            ></KeyValuePair>
          </Column>
        </ColumnLayout>
      </Container>
      <Modal
        title={<h3>Customer Reference</h3>}
        visible={open}
        onClose={() => handleClose()}
      >
        <FormField
          label='Enter the Customer you are using this demo for'
          controlId='formFieldId1'
        >
          <Input
            type='text'
            name='custName'
            controlId='formFieldId1'
            onChange={(value) =>
              setCustomerInfo({
                ...customerInfo,
                customerName: value,
                projectID: demoid,
              })
            }
          />
        </FormField>
        <FormField label='Enter the Opportunity ID' controlId='formFieldId1'>
          <Input
            type='text'
            name='opptyID'
            controlId='formFieldId1'
            onChange={(value) =>
              setCustomerInfo({
                ...customerInfo,
                opportunityID: value,
              })
            }
          />
        </FormField>
        <Button variant='primary' onClick={() => onSubmit()} isColor='primary'>
          Proceed to Demo
        </Button>
      </Modal>
      <Tabs tabs={tabs} variant='container' />
    </Stack>
  )
}

export default DemoDetailsPage
