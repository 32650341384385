// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Project, Tag, Vote, Industry, CloudService, ProjectComment, User, CustomerInfo, ProjectTag, ProjectIndustry, ProjectCloudService } = initSchema(schema);

export {
  Project,
  Tag,
  Vote,
  Industry,
  CloudService,
  ProjectComment,
  User,
  CustomerInfo,
  ProjectTag,
  ProjectIndustry,
  ProjectCloudService
};