import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import styles from './ProjectCard.module.scss'
import { Storage } from '@aws-amplify/storage'

import Card from 'aws-northstar/components/Card'
import Button from 'aws-northstar/components/Button'
import Heading from 'aws-northstar/components/Heading'
import Link from 'aws-northstar/components/Link'
import Box from 'aws-northstar/layouts/Box'
import Stack from 'aws-northstar/layouts/Stack'
// import Icon from 'aws-northstar/components/Icon'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import OpenInNew from '@material-ui/icons/OpenInNew'
// :: ---

function ProjectCard({ project }) {
  let darkColors = [
    'bf0816',
    'bf2d06',
    'd45b07',
    'e38b00',
    '067f68',
    '2e27ad',
    '4d27aa',
    'b0084d',
  ]

  const getThumbnailKey = (keyStr) => {
    let fStrExtPos = keyStr.lastIndexOf('.')
    return (
      'thumbs/' +
      keyStr.substr(0, fStrExtPos < 0 ? keyStr.length : fStrExtPos) +
      '.png'
    )
  }
  const [firstKeyDoesntExist, setFirstKeyDoesntExist] = useState(true)
  const [thumbPresignedURL, setthumbPresignedURL] = useState('')
  useEffect(() => {
    // set firstKeyDoesntExist to false if nothing uploaded or thumbnail not (yet) present in expected directory
    async function checkKey() {
      if (isEmpty(project.thumbnailKeys)) {
        setFirstKeyDoesntExist(true)
      } else {
        const response = await Storage.list(
          getThumbnailKey(project.thumbnailKeys[0])
        )
        setFirstKeyDoesntExist(response.length == 0)
      }
    }
    checkKey()
  })

  useEffect(() => {
    async function fetchPresignedURLs() {
      const response = await Storage.get(
        getThumbnailKey(project.thumbnailKeys[0])
      )
      setthumbPresignedURL(response)
    }
    firstKeyDoesntExist ? {} : fetchPresignedURLs()
  }, [firstKeyDoesntExist])

  if (project.name !== undefined) {
    let placeholderBGCol =
      darkColors[parseInt(project.id, 16) % darkColors.length]
    let placeholder =
      "data:image/svg+xml,%3Csvg xmlns='http:%2F%2Fwww.w3.org%2F2000%2Fsvg' xmlns:xlink='http:%2F%2Fwww.w3.org%2F1999%2Fxlink' viewBox='0 0 100 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{opacity:0.5;}.cls-2{fill:%23" +
      placeholderBGCol +
      ";}.cls-3{fill:%23fff;}.cls-4{fill:none;}%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3EGeneral_light-bg%3C%2Ftitle%3E%3Cg id='Working'%3E%3Cimage class='cls-1' width='97' height='97' transform='translate(1 2)' xlink:href='data:image%2Fpng;base64,iVBORw0KGgoAAAANSUhEUgAAAGEAAABhCAYAAADGBs+jAAAACXBIWXMAAAsSAAALEgHS3X78AAADRklEQVR4Xu2dy04bQRBFTzsYIhIU5bFASTb5%2F%2F+KsggohIcCBncW1TVuxmM8kCzu4h6pZOjxbO6Z6h6vqtRamaKUUiYvmBdTd4Rdxust%2FBTQ%2F21eTm0F4eJR6IOE7slfTBRYxkvIsNcTNXTGweimRVtbdnWARbyEXsA9sOrqvq0DTULrghRwBBwDb1odsRFhCfOpbATcAtetbtr1+1LKutZasxNSwpIQ8AH42D5PCBGv8BkxlzwDHggBl8A5cNau55ZUgXowOguWwFvgE%2FAV+EyIOG7Xclsy+1kTW88NIeA78QDfEWJWwEMppTzVCZ+Bb8Ap0Q2HeEuaS25Fd0QX%2FGjrN8Av4DebLLe2ozwT3hIiToEvwDvgNZYwl5TwB7hoaxdErltn7K63ozycTwgB73l8Lpin6c8DiCf%2FmMcCBnoJeeguiLCXxBZ01Oo1ljCXlACR3SGR5ys2HTDkOO4E2IjohfRlCfvJ3wiZWZ%2FnVn5TEpLyRJn9zM7Nr5wCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUIYAkCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUIYAkCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUIYAkCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUIYAkCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUIYAkCWIIAliCAJQhgCQJYggCWIIAlCGAJAliCAJYggCUI8NSwu7qjzH6eld2UhJygmp8PXYEnDs4hZ29m9XluyeglpK0MfsVmgHNOU%2FXszXn0U2hviRxXPBYyyBh3Qj9T%2FoYY6JzzhD2PeT7jecyXRJ63RL7r%2FsspIW9KAVfESPOcqP0bTyZ%2FDlOTyc+JXHsRFbYljGfKQ5g8JuYJ+21qPlN5nrf%2FV%2FQSaq21lFK6m66An+0L18R08n4quTthP7nn57lwSQg4I%2FJNCdRa665OoN18wcRMeTOL8RZ%2F3WqrE0qtcUi3blgQgS+76mfKW8J88u0nRay6ugfWtYW%2F6+0ou2KBBfwLvYhxDQydMCxER2TgPgP+D%2F3vglpHoW9JGC6EDPMfGYef%2FAXAiw24l2MbQgAAAABJRU5ErkJggg=='%2F%3E%3Crect class='cls-2' x='8.5' y='8.5' width='82' height='82'%2F%3E%3Cpath class='cls-3' d='M89,10V89H10V10H89m3-3H7V92H92V7Z'%2F%3E%3Crect class='cls-4' width='100' height='100'%2F%3E%3Cpath class='cls-3' d='M50,23.85,27.35,36.92V63.08L50,76.15,72.65,63.08V36.92ZM69.65,37.5,50,48.85,30.35,37.5,50,26.15Zm-40.3,1.73L49,50.58V73.27L29.35,61.92ZM51,73.27V50.58L70.65,39.23V61.92Z'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"
    return (
      <Box className={styles.projectcard}>
        <Card
          className={styles.projectcard}
          title={
            <Link href={`/demos/${project.id}`}>
              <Heading variant='h3'>{project.name}</Heading>
              {project.hide ? <VisibilityOff /> : <></>}
            </Link>
          }
        >
          {/* FIXME Bulma CSS*/}
          <Stack spacing='none'>
            <img
              className={styles.scaleAspectRatio}
              src={
                firstKeyDoesntExist || thumbPresignedURL == ''
                  ? placeholder
                  : thumbPresignedURL
              }
            />
            {/* FIXME Bulma CSS END */}
            <div className={styles.projectcardsubtitle}>{project.subtitle}</div>
            <Button
              icon={OpenInNew}
              onClick={() =>
                window.open(project.url, '_blank', 'noopener,noreferrer')
              }
            >
              Quick Launch
            </Button>
          </Stack>
        </Card>
      </Box>
    )
  } else return <div>0 Demos Found</div>
}

export default ProjectCard
