import React, { useState, useCallback } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { useHistory } from 'react-router-dom'

import Button from 'aws-northstar/components/Button'
import Container from 'aws-northstar/layouts/Container'
import Input from 'aws-northstar/components/Input'
import TextArea from 'aws-northstar/components/Textarea'
import Stack from 'aws-northstar/layouts/Stack'
import Inline from 'aws-northstar/layouts/Inline'
import HeadingStripe from 'aws-northstar/components/HeadingStripe'

import { Industry } from 'models'

// :: ---

function AddIndustryPage() {
  const history = useHistory()
  const [industry, setIndustry] = useState({
    name: '',
    description: '',
  })

  const handleFormChange = useCallback((name, value) => {
    setIndustry((industry) => ({ ...industry, [name]: value }))
  }, [])

  const handleSaveModel = useCallback(async (industry) => {
    // :: TODO validation
    console.debug('Saving industry ...', industry)
    const payload = new Industry(industry)
    const response = await DataStore.save(payload)
    console.debug('Industry saved:', response)
    history.push('/industries')
  }, [])

  // :: ---

  return (
    <Stack>
      <HeadingStripe
        headingVariant='h3'
        title='Add an Industry'
        actionButtons={
          <Inline>
            <Button variant='primary' onClick={() => handleSaveModel(industry)}>
              Save Industry
            </Button>
            <Button variant='normal' onClick={() => history.goBack()}>
              Discard and Cancel
            </Button>
          </Inline>
        }
      />

      <Container headingVariant='h4' title='Industry Name'>
        <Input
          placeholder='Healthcare'
          type='text'
          name='name'
          onChange={(value) => handleFormChange('name', value)}
          value={industry.name}
        />
      </Container>

      <Container headingVariant='h4' title='Description'>
        <TextArea
          name='description'
          onChange={(e) => handleFormChange('description', e.target.value)}
          value={industry.description}
        />
      </Container>
    </Stack>
  )
}

export default AddIndustryPage
