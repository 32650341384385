import React, { useContext, useEffect, useState } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Auth } from '@aws-amplify/auth'
import ProjectList from 'components/common/ProjectList'

import DemoContext from '../../context/demo/demoContext'
import { Project } from 'models'

// :: ---

function MyDemosPage() {
  const demoContext = useContext(DemoContext)
  const [userName, setUserName] = useState('')

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log(user)
        setUserName(user.username)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    const fetchDemo = async () => {
      const res = await DataStore.query(Project, (p) => p.owner('eq', userName))
      demoContext.setProjects(res)
    }
    fetchDemo()
  }, [userName])

  return <ProjectList title={'My Demos'} />
}

export default MyDemosPage
