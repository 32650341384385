import React, { useState, useContext } from 'react'
import Inline from 'aws-northstar/layouts/Inline'
import { DataStore } from '@aws-amplify/datastore'
import { Auth } from '@aws-amplify/auth'
import { Project } from 'models'
import { API, graphqlOperation } from '@aws-amplify/api'
import styles from './SearchInput.module.scss'
import santise, { sanitise } from '../../utils/sanitise'
import Button from 'aws-northstar/components/Button'
import Select from 'aws-northstar/components/Select'
import DemoContext from '../../context/demo/demoContext'
import Input from 'aws-northstar/components/Input'
import { Subtitles } from '@material-ui/icons'
// :: ---

function SearchInput({ pageChange }) {
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setsearchBy] = useState('Projects')
  const [selectedOption, setSeletedOption] = useState()
  const demoContext = useContext(DemoContext)
  const searchBarQuery = /* GraphQL */ `
    query searchBarQuery(
      $filter: SearchableProjectFilterInput
      $sort: SearchableProjectSortInput
      $limit: Int
      $nextToken: String
      $from: Int
    ) {
      searchProjects(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
      ) {
        items {
          id
          name
          subtitle
          description
          like
          dislike
          hours
          thumbnailKeys
          url
          owner
          contributors
          hide
          tags {
            nextToken
            startedAt
          }
          industries {
            nextToken
            startedAt
          }
          services {
            nextToken
            startedAt
          }
          comments {
            nextToken
            startedAt
          }
          customers {
            nextToken
            startedAt
          }
        }
        nextToken
        total
      }
    }
  `
  // :: ---
  const performSearchInput = async (searchValue) => {
    // RFE change query to predicate format with pagination
    // instead of pulling all projects
    try {
      if (searchBy == 'Projects') {
        const f = {
          name: { wildcard: '*' + searchValue.toLowerCase() + '*' },
        }
        const test = await API.graphql(
          graphqlOperation(searchBarQuery, { filter: f })
        )
        // console.log(test.data.searchProjects.items)
        demoContext.setProjects(test.data.searchProjects.items)
      } else if (searchBy == 'Contributors') {
        const f = {
          contributors: { wildcard: '*' + searchValue.toLowerCase() + '*' },
        }
        const test = await API.graphql(
          graphqlOperation(searchBarQuery, { filter: f })
        )
        demoContext.setProjects(test.data.searchProjects.items)
      } else if (searchBy == 'Subtitle') {
        const f = {
          subtitle: { wildcard: '*' + searchValue.toLowerCase() + '*' },
        }
        const test = await API.graphql(
          graphqlOperation(searchBarQuery, { filter: f })
        )
        demoContext.setProjects(test.data.searchProjects.items)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const options = [
    { label: 'Projects', value: 'Projects' },
    { label: 'Contributors', value: 'Contributors' },
    { label: 'Subtitle', value: 'Subtitle' },
  ]

  const handleFormChange = async (value) => {
    const cleanVal = sanitise(value)
    setSearchValue(cleanVal)
    console.log(searchValue)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
  }
  const handleFormButtonPress = async () => {
    if (searchValue === '') {
      demoContext.retrieveProjects()
    } else performSearchInput(searchValue)
    pageChange()
  }
  const handleSearchType = (event) => {
    setSeletedOption(options.find((o) => o.value === event.target.value))
    setsearchBy(event.target.value)
  }
  return (
    <form onSubmit={(e) => e.preventDefault()} className={styles.searchBar}>
      <Inline>
        <Input
          placeholder='Search'
          type='search'
          onChange={(value) => handleFormChange(value)}
        />
        <Select
          placeholder='Projects'
          options={options}
          selectedOption={selectedOption}
          onChange={handleSearchType}
        />
        <Button
          variant='primary'
          type='submit'
          onClick={() => handleFormButtonPress()}
        >
          Search
        </Button>
      </Inline>
    </form>
  )
}

export default SearchInput
