import React, { useState, useEffect, useCallback } from 'react'
import { DataStore } from '@aws-amplify/datastore'
import { Auth } from '@aws-amplify/auth'
import { Analytics } from '@aws-amplify/analytics'
import santise, { sanitise } from '../../utils/sanitise'
import { ProjectComment } from 'models'
import Peccy from '../../images/peccy.jpg'
import DemoCommentsTabPane from './DemoCommentsTabPane'
import Button from 'aws-northstar/components/Button'
import Inline from 'aws-northstar/layouts/Inline'
import Stack from 'aws-northstar/layouts/Stack'
import Text from 'aws-northstar/components/Text'
import FormField from 'aws-northstar/components/FormField'
import KeyValuePair from 'aws-northstar/components/KeyValuePair'
import Input from 'aws-northstar/components/Input'

function CommentItem({ comment, changeModel }) {
  // Hide IDP prefix on front-end
  // TODO centralise this config
  const [model, setModel] = useState({ comment: '', owner: '' })
  let result
  const [isEditting, setisEditting] = useState(false)
  const [commentThing, setComment] = useState(comment.content)
  const [comments, setComments] = useState([])
  const [newComment, setnewComment] = useState(comment.content)
  const [accOwner, setaccOwner] = useState()
  const IDP_PREFIX = 'FederateOIDC_'
  const handleUpdateForm = (name, value) => {
    setComment(value)
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (value.length > 0) {
          setModel((model) => ({
            ...model,
            [name]: value,
            owner: user.username,
          }))
        } else {
          setModel((model) => ({
            ...model,
            [name]: commentThing,
            owner: accOwner,
          }))
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setaccOwner(user.username)
      })
      .catch((err) => console.log(err))
  })
  const clickEdit = (owner) => {
    if (owner == accOwner.split(IDP_PREFIX)[1]) {
      setisEditting(true)
    } else {
      alert('you cannot edit this comment')
    }
  }
  const clickSave = async () => {
    // console.log(model == {})
    // query for a comment
    if (model != {} && model != null && model.comment != '') {
      const original = await DataStore.query(ProjectComment, comment.id)
      await DataStore.save(
        ProjectComment.copyOf(original, (updated) => {
          updated.content = model.comment
        })
      )
    }
    setisEditting(false)
    await changeModel()
  }
  const clickCancel = () => {
    setisEditting(false)
  }
  if (isEditting) {
    result = (
      <Inline>
        <img src={Peccy} />
        <Input
          type='text'
          // onChange={(value) => handleUpdateForm('comment', sanitise(value))}
          value={commentThing}
          onChange={(value) => handleUpdateForm('comment', sanitise(value))}
        />
        <Button variant='secondary' onClick={() => clickSave()}>
          Save
        </Button>
        <Button variant='primary' size='small' onClick={() => clickCancel()}>
          X
        </Button>
      </Inline>
    )
  } else {
    result = (
      <Inline>
        <img src={Peccy} />
        <KeyValuePair
          label={comment.owner.split(IDP_PREFIX)[1]}
          value={comment.content}
        />
        <Button onClick={() => clickEdit(comment.owner.split(IDP_PREFIX)[1])}>
          {' '}
          Edit Comment{' '}
        </Button>
      </Inline>
    )
  }
  return result
}

export default CommentItem
